import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Resume from "../../static/aee-resume.pdf";

import Button from "react-bootstrap/Button";

import { FiDownload } from "react-icons/fi";

const AboutPage = ({ data: { site } }) => {
  return (
    <Layout>
      <Helmet>
        <title>About — {site.siteMetadata.title}</title>
        <meta
          name="description"
          content={"About page of Andreas. " + site.siteMetadata.description}
        />
      </Helmet>
      <Container className="section about">
        <div
          className="banner"
          style={{
            backgroundImage: `url(https://images.unsplash.com/photo-1598468807138-175e2210c59c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8)`,
          }}
        />
        <Row className="header">
          <Col sm={12} md="auto">
            <Image src="/assets/portrait.webp"></Image>
          </Col>
          <Col sm={12} md="auto" className="text">
            <a href={Resume}>
              <FiDownload className="mr-1" />
              Resume
            </a>
            <h2>{site.siteMetadata.home.name}</h2>
            <p>{site.siteMetadata.home.role}</p>
          </Col>
        </Row>

        <Row>
          <Col md={6} className="aboutme">
            <h2>About me</h2>
            <p>
              My name is Andreas Eriksen, and I am a software engineer and
              student based in Copenhagen, Denmark. With a passion for
              technology and an eye for detail, I strive to develop innovative
              solutions that push the boundaries of what's possible.
              <br></br>
              <br></br>
              As an experienced developer, I have honed my skills in various
              programming languages and technologies, including Typescript, SQL,
              Bash, Javascript & Python and many more. I am constantly learning
              and seeking new challenges to help me grow both personally and
              professionally.
              <br></br>
              <br></br>I take pride in my ability to work collaboratively with
              teams and clients to deliver high-quality results that meet their
              unique needs. From project planning to execution, I am dedicated
              to taking each project to the next level and achieving success.
              <br></br>
              <br></br>
              If you're interested in discussing a potential project or job
              opportunity, or just want to chat, feel free to reach out to me. I
              look forward to hearing from you.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export default AboutPage;
export const pageQuery = graphql`
  query AboutPageQuery {
    site {
      siteMetadata {
        title
        description
        home {
          name
          role
          location
        }
      }
    }
  }
`;
